import { Fragment } from "react";
import { Link } from "../Link";
import Layout from "./Layout";

type Props = {
  children: React.ReactNode;
};

type Link =
  | [title: string, target: string]
  | {
      title: string;
      links: [title: string, target: string][];
    };

export const LegalLinkTarget = Object.freeze({
  IMPRINT: "/legal/imprint",
  Privacy: {
    WEBSITE: "/legal/privacy/website",
    PLATFORM: "/legal/privacy/platform",
  },
  Users: {
    TERMS_AND_CONDITIONS: "/legal/terms-and-conditions/users",
    SPECIFICATION_OF_SERVICES: "/legal/specification-of-services/users",
  },
  Business: {
    TERMS_AND_CONDITIONS: "/legal/terms-and-conditions/business",
    SPECIFICATION_OF_SERVICES: "/legal/specification-of-services/business",
    DPA: "/legal/dpa",
  },
});

const links: Link[] = [
  ["Impressum", LegalLinkTarget.IMPRINT],
  ["Datenschutzerklärung (Webseite)", LegalLinkTarget.Privacy.WEBSITE],
  ["Datenschutzerklärung (Plattform)", LegalLinkTarget.Privacy.PLATFORM],
  {
    title: "Nutzer",
    links: [
      [
        "Allgemeine Geschäftsbedingungen",
        LegalLinkTarget.Users.TERMS_AND_CONDITIONS,
      ],
      [
        "Leistungsbeschreibung",
        LegalLinkTarget.Users.SPECIFICATION_OF_SERVICES,
      ],
    ],
  },
  {
    title: "Unternehmen",
    links: [
      [
        "Allgemeine Geschäftsbedingungen",
        LegalLinkTarget.Business.TERMS_AND_CONDITIONS,
      ],
      [
        "Leistungsbeschreibung",
        LegalLinkTarget.Business.SPECIFICATION_OF_SERVICES,
      ],
      ["Auftragsverarbeitungsvertrag", LegalLinkTarget.Business.DPA],
    ],
  },
];

const LegalLayout = ({ children }: Props) => {
  return (
    <Layout
      title="Rechtliches"
      description="Rechtliche Informationen zu Club of Code"
    >
      <h2 className="text-4xl font-bold mb-8">Rechtliches</h2>
      <div className="flex gap-x-4">
        <div className="pr-4">
          <ul className=" ">
            {links.map((link) => {
              const isCategory = "title" in link;

              if (isCategory) {
                return (
                  <Fragment key={link.title}>
                    <li className="block font-medium mt-4 mb-2">
                      <span>{link.title}</span>
                    </li>
                    {link.links.map(([title, target]) => (
                      <li
                        key={target}
                        className="text-lg hover:bg-gray-200 rounded min-w-[180px] mb-1"
                      >
                        <Link
                          to={target}
                          className="px-4 py-2 hover:font-medium block"
                        >
                          {title}
                        </Link>
                      </li>
                    ))}
                  </Fragment>
                );
              } else {
                const [title, target] = link;

                return (
                  <li
                    key={target}
                    className="text-lg hover:bg-gray-200 rounded min-w-[180px] mb-1"
                  >
                    <Link
                      to={target}
                      className="px-4 py-2 hover:font-medium block"
                    >
                      {title}
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <div className="flex-grow">{children}</div>
      </div>
    </Layout>
  );
};

export default LegalLayout;
