import { useState } from "react";
import { Container, Logo } from "@clubofcode/ui";
import { Link } from "../Link";

const links = [
  ["Impressum", "/legal/imprint"],
  ["Datenschutz", "/legal/privacy/website"],
  ["Rechtliches", "/legal"],
  ["Über uns", "/about-us"],
];

const Footer = () => {
  const [thisYear] = useState(() => new Date().getFullYear());

  return (
    <footer
      id="footer"
      className="bg-gradient-to-tr from-brand to-black pt-12 mt-24"
    >
      <Container className="relative bottom-0 z-10 pb-2 sm:pb-6 pointer-events-auto">
        <div className="text-right">
          <Link to="/" className="block mb-4">
            <div className="hidden sm:block">
              <Logo
                gradientId="logo-footer_default"
                height={48}
                withText
                type="light"
              />
            </div>
            <div className="block sm:hidden">
              <Logo
                gradientId="logo-footer_small"
                height={36}
                withText
                type="light"
              />
            </div>
          </Link>
        </div>
        <hr className="border-0 border-b border-white-divide" />
        <div className="md:flex md:justify-between text-sm uppercase tracking-wider my-4 text-white-secondary">
          <div className="block text-center md:text-left">
            &#169; {thisYear}{" "}
            <Link to="/" className="hover:text-white">
              Club of Code
            </Link>{" "}
            - Alle Rechte vorbehalten.
          </div>
          <ul className="text-center">
            {links.map(([title, target]) => (
              <li key={target} className="inline-block mr-8 last:mr-0">
                <Link to={target} className="hover:text-white">
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
