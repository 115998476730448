import React, { ReactNode } from "react";
import Head from "next/head";
import { Container } from "@clubofcode/ui";

import { Header } from "../Header";
import { Footer } from "../Footer";
import { SEOMeta } from "../Meta";

type Props = {
  children?: ReactNode;
  title: string;
  titleSuffix?: string;
  description: string;
};

const Layout = ({
  children,
  title,
  titleSuffix = " - Club of Code",
  description
}: Props) => {
  return (
    <div className="flex flex-col min-h-screen text-brand">
      <Head>
        <title>{title + titleSuffix}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <SEOMeta title={title} description={description} />
      <Header />
      <Container className="flex-grow">{children}</Container>
      <Footer />
    </div>
  );
};

export default Layout;
