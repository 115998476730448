import { Maybe } from "@clubofcode/types";
import { Container } from "@clubofcode/ui";
import Head from "next/head";
import React from "react";
import { PoweredBy } from "../PoweredBy";

type Props = {
  companyName?: Maybe<string>;
  children: React.ReactNode;
};

const CompanyProfileLayout = ({ companyName, children }: Props) => {
  const title = companyName ? `Jobs bei ${companyName}` : "Jobs";

  return (
    <div className="min-h-screen text-brand bg-gray-100">
      <Head>
        <title>{`${title} - Club of Code`}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {/* TODO: add SEO */}
      {/* <SEOMeta title={title} description={description} /> */}
      <Container className="flex flex-col min-h-screen max-w-[984px] bg-white border-x border-gray-200">
        <div className="flex-grow">{children}</div>
        <PoweredBy />
      </Container>
    </div>
  );
};

export default CompanyProfileLayout;
