import { Logo } from "@clubofcode/ui";
import { Link } from "~/components/Link";

const PoweredBy = () => {
  return (
    <div className="flex flex-col items-center gap-y-2 mt-28 mb-12">
      <span className="text-lg font-medium text-gray-500">powered by</span>
      <Link to="/">
        <Logo height={36} withText type="dark" />
      </Link>
    </div>
  );
};

export default PoweredBy;
